import { gql } from "@apollo/client";
import { HexId, HexVersionString, OrgRole } from "@hex/common";
import React, { ReactNode, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { useCurrentUser } from "./hooks/me/useCurrentUser";
import { useGetThemeFromName } from "./hooks/useGetThemeFromName";
import { useGlobalHotKey } from "./hooks/useGlobalHotKey";
import { LocalStorageKeys, useLocalStorage } from "./hooks/useLocalStorage";
import { useThemeOverride } from "./route/routeHooks.js";
import { Routes } from "./route/routes";
import { useGetHexVersionQuery } from "./ThemeWrapper.generated";

gql`
  query GetHexVersion($hexId: HexId!, $version: HexVersionString!) {
    hexVersionByNumber(hexId: $hexId, version: $version) {
      id
      appTheme
    }
  }
`;

interface ThemeWrapperProps {
  children: ReactNode;
}

const ThemeWrapper: React.FunctionComponent<ThemeWrapperProps> = ({
  children,
}) => {
  const isAnonymous = useCurrentUser()?.orgRole === OrgRole.ANONYMOUS;
  const [userThemeName] = useLocalStorage(LocalStorageKeys.THEME_NAME);

  const routeMatch = useRouteMatch(Routes.APP);
  const isAppRoute = routeMatch != null;

  const params: { hexId?: HexId; version?: HexVersionString } =
    routeMatch?.params ?? {};
  const hexId: HexId = params.hexId ?? ("" as HexId);
  const version: HexVersionString = params?.version ?? ("" as HexVersionString);

  const { data } = useGetHexVersionQuery({
    skip: !isAppRoute || hexId === "",
    variables: {
      hexId,
      version,
    },
  });
  const hexVersionAppTheme = data?.hexVersionByNumber.appTheme;

  const themeName =
    isAnonymous && isAppRoute
      ? hexVersionAppTheme ?? userThemeName
      : userThemeName;

  const resolvedTheme = useThemeOverride() ?? themeName;
  const themeFromName = useGetThemeFromName(resolvedTheme);

  const [isKonamiMode, setIsKonamiMode] = useState(false);
  useGlobalHotKey(
    () => {
      setIsKonamiMode((mode) => !mode);
      return true;
    },
    [],
    {
      keys: "up up down down left right left right b a enter",
      name: "Konami code",
    },
  );

  const theme = useMemo(() => {
    if (isKonamiMode) {
      return {
        ...themeFromName,
        fontFamily: {
          ...themeFromName.fontFamily,
          DEFAULT: "Comic Sans MS, Comic Sans",
        },
      };
    }

    return themeFromName;
  }, [isKonamiMode, themeFromName]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
